import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';

import { Channels } from '@slc-libs/enums';

import { environment } from '@slice-env/environment';

import { E_ROUTES } from '@slice-enums/routes.enum';

import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class ServerErrToastrService {
  constructor(
    private messageS: MessageService,
    private tS: TranslocoService,
    private navS: NavigationService,
  ) {}

  handleError(err: HttpErrorResponse): void {
    // hide from real clients notification
    if (this.isAcademy(err)) {
      console.log('ERROR: Academy service', err);
      return;
    }

    if (err.status === 500) {
      this.handle_500(err);
    } else if (err.status === 404) {
      if (
        !this.isPublicRateCard(err) &&
        !this.isContractPublicPage(err) &&
        !this.isContractTermOfPayment(err)
      ) {
        this.handle_404(err);
      }
    } else if (err.status === 403) {
      this.handle_403(err);
    } else if (err.status === 400) {
      if (this.isNotLoginRequest() && !this.isVerifyEmailRequest(err)) {
        this.handle_400(err);
      }
    } else {
      console.log('ERROR: Not handled by push-notification service', err);
    }
  }

  private isIgAnalyticsReq(err: HttpErrorResponse): boolean {
    return Boolean(
      err.url?.includes(`/creator-analytics/${Channels.INSTAGRAM}`),
    );
  }

  private isAcademy(err: HttpErrorResponse): boolean {
    return (
      Boolean(err.url?.includes(`/squarespace`)) ||
      Boolean(err.url?.includes(`/creators/academy`))
    );
  }

  private isVerifyEmailRequest(err: HttpErrorResponse): boolean {
    return Boolean(err.url?.includes(`/confirmations/`));
  }

  private isNotLoginRequest(): boolean {
    return (
      this.navS.getRoutePath(E_ROUTES.SIGN_IN) !== window.location.pathname
    );
  }

  private isPublicRateCard(err: HttpErrorResponse): boolean {
    return Boolean(err.url?.includes(`/public/v1/rate-card/styling`));
  }

  private isContractPublicPage(err: HttpErrorResponse): boolean {
    return Boolean(err.url?.includes(`/public/v1/contract`));
  }

  private isContractTermOfPayment(err: HttpErrorResponse): boolean {
    return Boolean(err.url?.includes(`/campaign/term-of-payments`));
  }

  // api not implemented yet
  private handle_404(err: HttpErrorResponse): void {
    console.error(err);
    const fullUrl = err.url?.split('?');
    const clearUrl = fullUrl ? fullUrl[0] : undefined;
    if (environment.production) {
      if (environment.isDemo) {
        // don't show notific
      } else if (environment.isStage) {
        this.messageS.add({
          summary: 'Development Notification',
          severity: 'error',
          life: 5 * 1000,
          detail: 'Api endpoint dont exist for - ' + clearUrl,
        });
        // prod
      } else {
        // don't show notific
      }
    } else {
      this.messageS.add({
        summary: 'Development Notification',
        severity: 'error',
        life: 5 * 1000,
        detail: 'Api endpoint dont exist for - ' + clearUrl,
      });
    }
  }

  // unexpected error
  private handle_500(err: HttpErrorResponse): void {
    console.error(err);
    const show = () => {
      this.showError(
        {
          summaryKey: 'common.error',
          detailsKey: 'common.error-500',
        },
        err,
      );
    };
    if (environment.production) {
      if (environment.isDemo) {
        show();
      } else if (environment.isStage) {
        show();
      } else {
        show();
      }
    } else {
      show();
    }
  }

  // forbidden
  private handle_403(err: HttpErrorResponse): void {
    console.error(err);
    const show = () => {
      this.showError(
        {
          summaryKey: 'common.error',
          detailsKey: 'common.error-403',
        },
        err,
      );
    };
    if (environment.production) {
      if (environment.isDemo) {
        show();
      } else if (environment.isStage) {
        show();
      } else {
        show();
      }
    } else {
      show();
    }
  }

  // to BE provided invalid data
  private handle_400(err: HttpErrorResponse): void {
    console.error(err);
    const show = () => {
      this.showError(
        {
          summaryKey: 'common.error',
          detailsKey: 'common.error-400',
        },
        err,
      );
    };
    if (environment.production) {
      if (environment.isDemo) {
        show();
      } else if (environment.isStage) {
        show();
      } else {
        show();
      }
    } else {
      show();
    }
  }

  private showError(
    d: { summaryKey?: string; detailsKey?: string },
    err: HttpErrorResponse,
  ): void {
    if (
      err?.error?.message &&
      err?.error?.exception &&
      err?.error?.message?.length < 20 &&
      err?.error?.exception?.length < 20
    ) {
      this.messageS.add({
        severity: 'warn',
        summary: err.error.message,
        life: 7 * 1000,
        detail: err.error.exception,
      });
    } else {
      this.messageS.add({
        severity: 'error',
        summary: d.summaryKey
          ? err.status + ' ' + this.tS.translate(d.summaryKey)
          : undefined,
        life: 7 * 1000,
        detail: d.detailsKey ? this.tS.translate(d.detailsKey) : undefined,
      });
    }
  }
}
