<ng-container *ngIf="stream$ | async as stream">
  <header class="slc-flex-sb-c">
    <slc-svg-icon-logo
      [isProKit]="stream.isProKit"
      [isLight]="false"
      [routerLink]="['/']"
      *ngIf="stream.isMenuOpen"
    ></slc-svg-icon-logo>

    <button type="button" (click)="toggleMenu()">
      <slc-svg-icon-hamburger
        *ngIf="!stream.isMenuOpen"
      ></slc-svg-icon-hamburger>
      <slc-svg-icon-close *ngIf="stream.isMenuOpen"></slc-svg-icon-close>
    </button>
  </header>

  <main class="slc-flex-sb-c">
    <nav>
      <ng-container *ngFor="let item of items$ | async">
        <p-divider *ngIf="item.isTopDivider"></p-divider>
        <a
          *ngIf="!item.isBrand && item.childs; else noChild"
          class="menu-item menu-item-top-level"
          [ngClass]="{ 'is-active': hasActiveChildRoute(item) }"
          (click)="
            stream.isDesktop && !stream.isMenuOpen
              ? openFirstChild(item)
              : toggleShowMore(item)
          "
        >
          <ng-container *ngTemplateOutlet="brandCampaignTmpl"></ng-container>
        </a>
        <ng-template #noChild>
          <a
            *ngIf="!item.isBrand"
            class="menu-item menu-item-top-level"
            [routerLink]="item.urlSegments"
            [queryParams]="item.queryParams"
            (click)="
              !stream.isDesktop && stream.isMenuOpen ? toggleMenu(item) : null;
              mixpanelEventLog(item)
            "
            routerLinkActive="is-active"
          >
            <ng-container *ngTemplateOutlet="brandCampaignTmpl"></ng-container>
          </a>
        </ng-template>

        <button
          *ngIf="item.isBrand"
          class="menu-item menu-item-top-level"
          (click)="
            !stream.isDesktop && stream.isMenuOpen ? toggleMenu(item) : null;
            mixpanelEventLog(item)
          "
        >
          <ng-container *ngTemplateOutlet="brandCampaignTmpl"></ng-container>
        </button>

        <ng-template #brandCampaignTmpl>
          <div class="icon-wrap slc-flex-c-c">
            <slc-svg-icon-brand
              *ngIf="item.isBrand; else iconTmpl"
            ></slc-svg-icon-brand>

            <ng-template #iconTmpl>
              <slice-menu-item-icon
                [routeType]="item.route"
              ></slice-menu-item-icon>
            </ng-template>
          </div>

          <button
            type="button"
            (click)="toggleChilds(item)"
            class="slc-flex-sb-c slc-flex-grow-1"
          >
            <span [hidden]="!stream.isMobile && !stream.isMenuOpen"
              >{{ item.titleKey ? (item.titleKey | transloco) : item.title }}
            </span>
            <slc-svg-icon-chevron
              [hidden]="!stream.isMobile && !stream.isMenuOpen"
              [ngClass]="{ 'slc-rotate-180': !item.showAllItems }"
              *ngIf="!hiddenItemChilds[item.id] && item.childs"
            ></slc-svg-icon-chevron>
          </button>
        </ng-template>

        <div
          class="route-childs"
          *ngIf="!hiddenItemChilds[item.id] && item.childs && stream.isMenuOpen"
        >
          <ng-container *ngFor="let subItem of item.childs">
            <div *ngIf="!subItem.isHidden">
              <a
                [attr.slice-data-campaign-status]="subItem.campaign?.status"
                class="menu-item"
                (click)="
                  !stream.isDesktop && stream.isMenuOpen
                    ? toggleMenu(subItem)
                    : null;
                  showDataLoader(subItem);
                  mixpanelEventLog(subItem)
                "
                [ngClass]="{
                  'disabled-link': !subItem.urlSegments
                }"
                [routerLinkActiveOptions]="{
                  exact: !!subItem.urlSegments
                }"
                [routerLink]="subItem.urlSegments ? subItem.urlSegments : null"
                [queryParams]="subItem.queryParams"
                routerLinkActive="{{
                  subItem.urlSegments ? 'is-active-sub' : ''
                }}"
                ><span class="slc-ml-0 slc-text-ellipsis">
                  <slice-menu-item-icon
                    [routeType]="subItem.route"
                  ></slice-menu-item-icon>
                  <span>
                    {{
                      subItem.titleKey
                        ? (subItem.titleKey | transloco)
                        : subItem.title
                    }}
                  </span>
                </span>
                <slice-loader
                  *ngIf="
                    subItem?.campaign?.campaignId &&
                    subItem?.campaign?.campaignId === openingCampaignId
                  "
                  [showSvg]="true"
                  class="campaign-loader"
                ></slice-loader>
              </a>
            </div>
          </ng-container>
          <button
            class="btn-toggle-more"
            *ngIf="item.isExistHiddenItems"
            (click)="toggleShowMore(item)"
          >
            <slc-svg-icon-chevron
              [ngClass]="{ 'slc-rotate-180': !item.showAllItems }"
            ></slc-svg-icon-chevron>
            <span>{{
              (item.showAllItems
                ? 'menu.toggle-campaigns.less'
                : 'menu.toggle-campaigns.more'
              ) | transloco
            }}</span>
          </button>
        </div>
      </ng-container>
    </nav>

    <nav class="slc-mt-40">
      <ng-container *ngFor="let item of bottomItems$ | async">
        <p-divider *ngIf="item.isTopDivider"></p-divider>
        <a
          *ngIf="!item.isBrand && item.childs; else noChild"
          class="menu-item menu-item-top-level"
          [ngClass]="{ 'is-active': hasActiveChildRoute(item) }"
          (click)="
            stream.isDesktop && !stream.isMenuOpen
              ? openFirstChild(item)
              : toggleShowMore(item)
          "
        >
          <ng-container *ngTemplateOutlet="brandCampaignTmpl"></ng-container>
        </a>
        <ng-template #noChild>
          <a
            *ngIf="!item.isBrand"
            class="menu-item menu-item-top-level"
            [routerLink]="item.urlSegments"
            [queryParams]="item.queryParams"
            (click)="
              !stream.isDesktop && stream.isMenuOpen ? toggleMenu(item) : null;
              mixpanelEventLog(item)
            "
            routerLinkActive="is-active"
          >
          </a>
        </ng-template>

        <button
          *ngIf="item.isBrand"
          class="menu-item menu-item-top-level"
          (click)="
            !stream.isDesktop && stream.isMenuOpen ? toggleMenu(item) : null;
            mixpanelEventLog(item)
          "
        ></button>

        <ng-template #brandCampaignTmpl>
          <div class="icon-wrap slc-flex-c-c">
            <slc-svg-icon-brand
              *ngIf="item.isBrand; else iconTmpl"
            ></slc-svg-icon-brand>

            <ng-template #iconTmpl>
              <slice-menu-item-icon
                [routeType]="item.route"
              ></slice-menu-item-icon>
            </ng-template>
          </div>

          <button
            type="button"
            (click)="toggleChilds(item)"
            class="slc-flex-sb-c slc-flex-grow-1"
          >
            <span [hidden]="!stream.isMobile && !stream.isMenuOpen"
              >{{ item.titleKey ? (item.titleKey | transloco) : item.title }}
            </span>
            <slc-svg-icon-chevron
              [hidden]="!stream.isMobile && !stream.isMenuOpen"
              [ngClass]="{ 'slc-rotate-180': !item.showAllItems }"
              *ngIf="!hiddenItemChilds[item.id] && item.childs"
            ></slc-svg-icon-chevron>
          </button>
        </ng-template>

        <div
          class="route-childs"
          *ngIf="!hiddenItemChilds[item.id] && item.childs && stream.isMenuOpen"
        >
          <ng-container *ngFor="let subItem of item.childs">
            <div *ngIf="!subItem.isHidden">
              <a
                [attr.slice-data-campaign-status]="subItem.campaign?.status"
                class="menu-item"
                (click)="
                  !stream.isDesktop && stream.isMenuOpen
                    ? toggleMenu(subItem)
                    : null;
                  showDataLoader(subItem);
                  mixpanelEventLog(subItem)
                "
                [ngClass]="{
                  'disabled-link': !subItem.urlSegments
                }"
                [routerLinkActiveOptions]="{
                  exact: !!subItem.urlSegments
                }"
                [routerLink]="subItem.urlSegments ? subItem.urlSegments : null"
                [queryParams]="subItem.queryParams"
                routerLinkActive="{{
                  subItem.urlSegments ? 'is-active-sub' : ''
                }}"
                ><span class="slc-ml-0 slc-text-ellipsis">
                  <slice-menu-item-icon
                    [routeType]="subItem.route"
                  ></slice-menu-item-icon>
                  <span>
                    {{
                      subItem.titleKey
                        ? (subItem.titleKey | transloco)
                        : subItem.title
                    }}
                  </span>
                </span>
                <slice-loader
                  *ngIf="
                    subItem?.campaign?.campaignId &&
                    subItem?.campaign?.campaignId === openingCampaignId
                  "
                  [showSvg]="true"
                  class="campaign-loader"
                ></slice-loader>
              </a>
            </div>
          </ng-container>
          <button
            class="btn-toggle-more"
            *ngIf="item.isExistHiddenItems"
            (click)="toggleShowMore(item)"
          >
            <slc-svg-icon-chevron
              [ngClass]="{ 'slc-rotate-180': !item.showAllItems }"
            ></slc-svg-icon-chevron>
            <span>{{
              (item.showAllItems
                ? 'menu.toggle-campaigns.less'
                : 'menu.toggle-campaigns.more'
              ) | transloco
            }}</span>
          </button>
        </div>
      </ng-container>

      <a
        href="https://drive.google.com/drive/folders/1x0dsGVWqKLcEQ4OOU0U-xph65rh111vh"
        target="_blank"
        class="menu-item"
        hidden
        (click)="!stream.isDesktop && stream.isMenuOpen ? toggleMenu() : null"
      >
        <div class="icon-wrap slc-flex-c-c">
          <slc-svg-icon-info-square></slc-svg-icon-info-square>
        </div>
        <span [hidden]="!stream.isMobile && !stream.isMenuOpen">
          {{ 'menu.help-faq.title' | transloco }}
        </span>
      </a>

      <a
        [routerLink]="contactUsPath"
        routerLinkActive="is-active"
        class="menu-item"
        (click)="!stream.isDesktop && stream.isMenuOpen ? toggleMenu() : null"
      >
        <div class="icon-wrap slc-flex-c-c">
          <slc-svg-icon-info-square></slc-svg-icon-info-square>
        </div>
        <span [hidden]="!stream.isMobile && !stream.isMenuOpen">
          {{ 'menu.contact-us.title' | transloco }}
        </span>
      </a>

      <a
        href="https://slice.id/privacy-policy"
        target="_blank"
        class="menu-item"
        (click)="!stream.isDesktop && stream.isMenuOpen ? toggleMenu() : null"
      >
        <div class="icon-wrap slc-flex-c-c">
          <slc-svg-icon-checkbox-policy></slc-svg-icon-checkbox-policy>
        </div>
        <span [hidden]="!stream.isMobile && !stream.isMenuOpen">
          {{ 'menu.privacy-policy.title' | transloco }}
        </span>
      </a>
    </nav>
  </main>
</ng-container>
